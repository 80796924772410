import {useMemo} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IUserPreferencesFilterKeys} from "../interfaces/IUserPreferences";

export enum FilterPresets {
    All,
    WithoutRegion
}

const filterToEliminateTypesConfig: Record<FilterPresets, IUserPreferencesFilterKeys[]> = {
    [FilterPresets.All]: [],
    [FilterPresets.WithoutRegion]: ["region"]
};

export const useUserPreferencesFilters = (typeOfFilters = FilterPresets.All) => {
    const userPreferences = useSelector((state: IRPStore) => state.user.preferences);
    const userPreferencesFilters = userPreferences?.data?.filters;

    if (!userPreferencesFilters) {
        return {};
    }

    if (typeOfFilters !== FilterPresets.All) {
        return useMemo(() => {
            const filtersToEliminate = filterToEliminateTypesConfig[typeOfFilters];

            return Object.entries(userPreferencesFilters).reduce(
                (acc, [key, value]) => {
                    if (!filtersToEliminate.includes(key as IUserPreferencesFilterKeys)) {
                        acc[key] = value;
                    }

                    return acc;
                },
                {} as Record<string, string | string[]>
            ) as Partial<typeof userPreferencesFilters>;
        }, [typeOfFilters, userPreferencesFilters]);
    }

    return userPreferencesFilters;
};
