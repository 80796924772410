import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {ChevronLeftIcon, ChevronRightIcon} from "@pg-design/icons";

interface IProps {
    position: "left" | "right";
    wrapperColor: string;
    iconSize?: string;
    iconWrapperSize?: string;
    enableOnMobile?: boolean;
    fill?: string;
}

export const SliderGalleryIcon = (props: IProps) => {
    const iconProps = {
        size: props.iconSize || "2.4",
        wrapperColor: props.wrapperColor,
        wrapperSize: props.iconWrapperSize || "4.8",
        enableOnMobile: !!props.enableOnMobile,
        fill: props.fill
    };

    return <IconPosition {...props}>{props.position === "left" ? <ChevronLeftIcon {...iconProps} /> : <ChevronRightIcon {...iconProps} />}</IconPosition>;
};

const IconPosition = styled.div<IProps>`
    position: absolute;
    top: 50%;
    left: 1.6rem;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    display: ${(props) => (props.enableOnMobile ? "block" : "none")};

    ${(props) =>
        props.position === "right" &&
        css`
            left: auto;
            right: 1.6rem;
        `};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        display: block;
    }
`;
