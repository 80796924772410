import {getRequest} from "@pg-mono/request";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IApplicationOffer} from "../types/IApplicationOffer";

export const fetchApplicationOfferDetailById = (offerId: number): Promise<IApplicationOffer> => {
    const url = apiV2Link.offer.detail(Scenario.OFFER_APPLICATION, {offerId});

    return getRequest({}, url);
};
